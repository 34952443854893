<template>
    <div class="contenedor-grafica">
        <div id="chartdiv"></div>
    </div>
</template>


<script>

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
    name: 'grafica-barras',
    mounted() {
        this.setChart();
    },
    props: ['data'],
    data: () => ({
        root: '',
        series: ''
    }),
    methods: {
        setChart() {

            var root = am5.Root.new("chartdiv");

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            var chart = root.container.children.push(
                am5percent.PieChart.new(root, {
                    endAngle: 270
                })
            );

            var series = chart.series.push(
                am5percent.PieSeries.new(root, {
                    valueField: "value",
                    categoryField: "category",
                    endAngle: 270
                })
            );

            series.states.create("hidden", {
                endAngle: -90
            });

            var data = new Array();

            this.data.Series.forEach(e => {

                let el = {
                    category: e.category,
                    value: e.value,
                };

                data.push(el);
            });

            series.data.setAll(data);

            series.appear(1000, 100);
        }
    },
    watch: {
    }
}
</script>

<style lang="scss" scoped>
.contenedor-grafica #chartdiv {
    width: 100%;
}
</style>