<template>
    <div class="wrapper">

        <nav class="breadcrumb">
            <div class="container row-start">
                <router-link v-if="!checkroutepublico()" to="/">Inicio</router-link>

                <template v-if="from == 'ficha-cartas-servicios'">
                    <router-link :to="$t('enlaces.listadocartasservicios')">Cartas de servicios</router-link>
                    <router-link v-if="data.cartanombre"
                        :to="$t('enlaces.listadocartasservicios') + '/' + data.cartaid + '/indicadores'">
                        {{ data.cartanombre }}</router-link>
                </template>

                <template v-if="from == 'compromiso'">
                    <router-link :to="$route.fullPath">Compromisos de Gobierno</router-link>
                    <router-link :to="$route.fullPath">¿Cómo queremos que sea Madrid?</router-link>
                </template>

                <router-link :to="$route.fullPath" class="short">{{ data.nombre }}</router-link>
            </div>
        </nav>

        <a @click="back" class="back">Volver</a>
        <!-- <a v-if="!checkroutepublico()" @click="back" class="back">Volver</a> -->

        <section class="intro-ficha-indicador container">

            <div class="top">

                <div class="datos-carta" v-if="data.cartanombre">
                    <h2 class="title -white -left">{{ data.cartanombre }}</h2>
                </div>

                <!-- <div class="rrss row-end">
                    <a target="_blank" :href="`http://twitter.com/intent/tweet?url=${url}`" class="-twitter"></a>
                    <a target="_blank" :href="`http://www.facebook.com/sharer.php?u=${url}`" class="-facebook"></a>
                    <a target="_blank" :href="`https://wa.me?text=${url}`" class="-whatsapp"></a>
                    <a @click="copy" class="-link"></a>
                    <a :href="`mailto:?body=${url}`" class="-envelope"></a>
                </div> -->
            </div>
            <div class="bottom">

                <!-- <div class="resumen" :full="graficafull"> -->
                <div class="texto">
                    <h2 class="title -left">{{ data.nombre }}</h2>
                    <p class="descripcion-indicador" v-html="data.descripcion"></p>
                </div>
                <div class="grafica" :full="graficafull"
                    v-if="graph && ((graph.Metas && graph.Metas.length > 0) | (graph.Series && graph.Series.length >= 0))">
                    <grafica-columnas v-if="graph && graph.GraficoTipo == 'column' && graph.GraficoSerie == 'oculta'"
                        :data="graph">
                    </grafica-columnas>
                    <grafica-barras
                        v-if="graph && graph.GraficoTipo == 'bar' && (graph.GraficoSerie == 'oculta' | graph.GraficoSerie == '')"
                        :data="graph">
                    </grafica-barras>
                    <grafica-columnas-series
                        v-if="(graph && graph.GraficoTipo == 'column' && graph.GraficoSerie != 'oculta') || (graph && graph.GraficoTipo == 'oculta' && graph.GraficoSerie != 'oculta')"
                        :data="graph">
                    </grafica-columnas-series>
                    <grafica-barras-series v-if="graph && graph.GraficoTipo == 'bar' && graph.GraficoSerie != 'oculta'"
                        :data="graph">
                    </grafica-barras-series>

                    <grafica-area v-if="graph && graph.GraficoTipo == 'area'" :data="graph"></grafica-area>
                    <grafica-pie v-if="graph && graph.GraficoTipo == 'pie'" :data="graph"></grafica-pie>

                </div>
                <!-- </div> -->


                <div class="observaciones" v-if="data.observaciones">
                    <h3>Observaciones</h3>
                    <p v-html="data.observaciones"></p>
                </div>
            </div>




        </section>


        <section class="ficha-tecnica-indicador">
            <div class="container">
                <h2>Ficha técnica</h2>

                <div class="contenedor-ficha">

                    <h3 v-if="from == 'ficha-cartas-servicios'">Compromiso asociado al indicador</h3>

                    <div class="tips">
                        <div class="tip">
                            <h3>Responsable</h3>
                            <p>{{ data.areagobierno }}</p>
                        </div>
                        <div class="tip">
                            <h3>Fuente de datos</h3>
                            <p>{{ data.fuentedatos }}</p>
                        </div>
                        <div class="tip">
                            <h3>Fórmula de cálculo</h3>
                            <p>{{ data.formuladescrip }}</p>
                        </div>
                    </div>

                    <div class="tips">
                        <div class="tip">
                            <h3>Unidad de medida</h3>
                            <p>{{ data.unidadmedida }}</p>
                        </div>
                        <div class="tip">
                            <h3>Periodicidad</h3>
                            <p>{{ data.asperiodicidadpublicacion }}</p>
                        </div>

                    </div>

                </div>

            </div>
        </section>

        <banner-ayudamos :data="['plan-estrategico', 'que-es-pog', 'que-son-cartas-servicios']"></banner-ayudamos>

    </div>
</template>


<script>

import { mapActions } from 'vuex';

import graficacolumnas from '@/components/parts/grafica-columnas'
import graficacolumnasseries from '@/components/parts/grafica-columnas-series'

import graficabarras from '@/components/parts/grafica-barras'
import graficabarrasseries from '@/components/parts/grafica-barras-series'

import graficaarea from '@/components/parts/grafico-area'
import graficapie from '@/components/parts/grafico-pie'
import router from '@/router';

export default {
    name: 'ficha-indicador',
    mounted() {
        this.setData();
    },
    data: () => ({
        data: '',
        graph: '',
        from: ''
    }),
    methods: {
        ...mapActions([
            'requestIndicador',
            'requestGraficoIndicador'
        ]),
        setData() {
            this.requestIndicador(this.$route.params.id).then(r => {
                this.data = r;
            });

            this.requestGraficoIndicador(this.$route.params.id).then(r => {
                this.graph = r;
            });

            console.log(this);
        },
        copy() {
            let copyText = this.url;
            navigator.clipboard.writeText(copyText);
            alert("Enlace copiado");
        },
        back() {
            router.go(-1);
        },
        checkroutepublico() {
            if (this.$route.name == 'ficha-cartas-servicios-publico' || this.$route.name == 'ficha-indicador-publico') {
                return true;
            } else {
                return false;
            }
            // if (this.$route.path.includes('publico')) {
            //     return true;
            // } else {
            //     return false;
            // }
        }
    },
    computed: {
        url() {
            return location.href;
        },
        graficafull() {
            let full = false;
            if (this.graph) {
                if (this.graph.Metas && this.graph.Metas.length >= 10) {
                    full = true;
                } else if (this.graph.Series.length >= 10) {
                    full = true;
                } else if (this.graph.GraficoTipo == 'pie') {
                    full = true;
                } else if (this.graph.Agrupaciones && this.graph.Agrupaciones.length != 0) {
                    full = true;
                }
            }
            return full;
        }
    },
    components: {
        'grafica-columnas': graficacolumnas,
        'grafica-columnas-series': graficacolumnasseries,
        'grafica-barras': graficabarras,
        'grafica-barras-series': graficabarrasseries,
        'grafica-area': graficaarea,
        'grafica-pie': graficapie,
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.from = from.name;
        });
    }
}
</script>


<style lang="scss" scoped>
.title {
    font-size: 24px;
    font-weight: 400;
    font-family: "NeverMind Condensed";
    max-width: fit-content !important;
}

.descripcion-indicador {
    /** texto justificado */
    text-align: justify;

}
</style>