<template>
    <div class="contenedor-grafica">
        <nav class="tipos" v-if="data.Agrupaciones">
            <a :active="selected === null" @click="selected = null" v-if="data.Series.length > 0">Totales</a>
            <template v-for="(el, key) in data.Agrupaciones">
                <a :active="selected == key" @click="selected = key" :key="key" v-if="el.GraficoTipo != 'mapacalor'">{{
                    el.Agrupacion }}</a>
            </template>
            <a :active="selected == '-1'" @click="selected = '-1'"
                v-if="data.Agrupaciones.some(e => e.GraficoTipo == 'mapacalor')">Mapa de distritos</a>
        </nav>

        <div id="chartdiv" :isbig="isbig" v-show="selected != '-1'"></div>

        <mapa v-if="selected == '-1' && !!data"
            :data="data.Agrupaciones.filter(e => e.GraficoTipo == 'mapacalor')[0].Series"></mapa>

    </div>
</template>

<script>

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";

import mapa from '@/components/parts/mapa-distritos-indicador';

export default {
    name: 'grafica-columnas-series',
    mounted() {
        this.setChart();
    },
    props: ['data'],
    data: () => ({
        root: '',
        series: '',
        colors: ["#7CB9E8", "#C9FFE5", "#7FFFD4", "#ACDCDD", "#00FFEF", "#CCCCFF", "#00DDF3", "#87CEFA", "#B2FFFF", "#1DACD6", "#A7D3F3", "#00FFFF", "#00B0F6", "#40E0D0", "#AFE4DE", "#48D1CC", "#00CED1", "#AFEEEE", "#A0D6B4", "#0000FF", "#ADD8E6", "#7DF9FF", "#536878", "#0000CD", "#00008B", "#D1EDF2", "#72A0C1", "#0099FF", "#091F92", "#333C87", "#00438A", "#091F92", "#004F79", "#003153", "#5564EB", "#007E8B", "#0A3F7A", "#436EC0", "#0048BA", "#318CE7", "#003C92", "#213C6E", "#25206F", "#122562", "#6576B4", "#0093AF", "#4795CE", "#00209C", "#367793"],
        selected: null,
        mapacalor: false,
        isbig: false
    }),
    methods: {
        setChart() {

            this.root = am5.Root.new("chartdiv");

            this.root.setThemes([
                am5themes_Animated.new(this.root)
            ]);

            //this.root.numberFormatter.set("numberFormat", "#");
            this.root.locale = am5locales_es_ES;

            var chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                pinchZoomX: true,
                layout: this.root.verticalLayout
            }));

            var legend = chart.children.push(
                am5.Legend.new(this.root, {
                    centerX: am5.p50,
                    x: am5.p50,
                    fixedWidthGrid: true,
                    useDefaultMarker: true,
                })
            );

            legend.labels.template.setAll({
                fontSize: 14,
                fontWeight: "400"
            });

            var series;


            var data = new Object();
            var seriesnames = [];
            let graphseries;


            if (this.selected == null) {
                graphseries = this.data.Series;
                // si no hay series totales seleccionamos la primera pestaña
                if (this.data.Series.length == 0) {
                    this.selected = 0;
                }
            } else {
                graphseries = this.data.Agrupaciones[this.selected].Series;
            }


            graphseries.forEach(e => {

                if (!data[e.name]) {
                    let el = {
                        year: e.name.toString(),
                    };
                    el[e.category] = e.value;
                    data[e.name] = el;
                } else {
                    data[e.name][e.category] = e.value;
                    data[e.name][e.color] = e.Color;
                }

                if (!seriesnames.some(s => s == e.category)) {
                    seriesnames.push(e.category);
                }

            });

            data = Object.values(data);

            var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(this.root, {
                categoryField: "year",
                renderer: am5xy.AxisRendererX.new(this.root, {
                    cellStartLocation: 0.1,
                    cellEndLocation: 0.9
                }),
                tooltip: am5.Tooltip.new(this.root, {})
            }));

            xAxis.data.setAll(data);

            let escalaCeroDiez = this.data.EscalaCeroDiez;

            var yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(this.root, {
                    min: (data.some(e => e.value < 0)) ? -20 : 0,
                    max: (escalaCeroDiez) ? 9 : null,
                    extraMax: 0.1,
                    renderer: am5xy.AxisRendererY.new(this.root, {
                    })
                }));

            // Add series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

            var that = this;

            function makeSeries(name, fieldName) {
                let col = graphseries.filter(e => e.category == name)[0].Color;
                if (col == null) {
                    col = that.colors[seriesnames.indexOf(name)];
                }
                series = chart.series.push(am5xy.ColumnSeries.new(that.root, {
                    name: name,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    fill: col,
                    valueYField: fieldName,
                    categoryXField: "year"
                }));

                series.columns.template.setAll({
                    tooltipText: "{name}, {categoryX}: {valueY}",
                    width: am5.percent(90),
                    tooltipY: 0
                });

                series.data.setAll(data);


                // Make stuff animate on load
                // https://www.amcharts.com/docs/v5/concepts/animations/
                series.appear();

                series.bullets.push(function () {
                    return am5.Bullet.new(that.root, {
                        locationY: 0,
                        sprite: am5.Label.new(that.root, {
                            text: "{valueY}",
                            fill: that.root.interfaceColors.get("alternativeText"),
                            centerY: 0,
                            centerX: am5.p50,
                            populateText: true
                        })
                    });
                });

                if (seriesnames.length != 1) {
                    legend.data.push(series);
                }

                if (seriesnames.length > 10) {
                    that.isbig = true;
                } else {
                    that.isbig = false;
                }


            }



            seriesnames.forEach(e => {
                makeSeries(e, e);
            })


            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear(1000);
            chart.appear(1000, 100);



        }
    },
    components: {
        'mapa': mapa
    },
    watch: {
        'selected'() {
            if (this.selected != '-1') {
                this.root.dispose();
                this.setChart();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.contenedor-grafica #chartdiv {
    width: 100%;
    height: 300px;

    &[isbig=true] {
        min-height: 700px;
        height: 700px;
    }
}
</style>