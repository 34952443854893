<template>
    <div class="contenedor-grafica -barras-mapa">
        <div id="chartdivbarras"></div>
    </div>
</template>


<script>

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
    name: 'grafico-barras-distritos',
    props: ['data'],
    data: () => ({
        root: '',
        series: [],
        graphseries: ''
    }),
    mounted() {
        this.setChart();
    },
    methods: {
        setChart() {

            this.root = am5.Root.new("chartdivbarras");


            this.root.setThemes([
                am5themes_Animated.new(this.root)
            ]);

            this.root.numberFormatter.set("numberFormat", "####");


            var chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
                panX: false,
                panY: false,
                layout: this.root.verticalLayout
            }));


            Object.entries(this.data).forEach((e) => {

                let el = {
                    "name": e[0],
                    "percent": e[1]
                };

                this.series.push(el);

            });



            var yAxis = chart.yAxes.push(
                am5xy.CategoryAxis.new(this.root, {
                    categoryField: "name",
                    renderer: am5xy.AxisRendererY.new(this.root, {
                        cellStartLocation: 0,
                        cellEndLocation: 1,
                        minGridDistance: 5,
                    }),
                    tooltip: am5.Tooltip.new(this.root, {})
                })
            );

            yAxis.data.setAll(this.series);

            var xAxis = chart.xAxes.push(
                am5xy.ValueAxis.new(this.root, {
                    min: 0,
                    max: 10,
                    renderer: am5xy.AxisRendererX.new(this.root, {}),

                })
            );

            this.graphseries = chart.series.push(am5xy.ColumnSeries.new(this.root, {
                name: "Distrito",
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: "percent",
                categoryYField: "name",
                sequencedInterpolation: false,
                minGridDistance: 5,
                tooltip: am5.Tooltip.new(this.root, {
                    pointerOrientation: "horizontal",
                    labelText: "[bold]{name}[/]\n{categoryY}: {valueX}%"
                })
            }));

            this.graphseries.columns.template.setAll({
                height: am5.percent(50)
            });



            // Add cursor
            // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
            var cursor = chart.set("cursor", am5xy.XYCursor.new(this.root, {
                behavior: "zoomX"
            }));
            cursor.lineX.set("visible", false);

            this.graphseries.data.setAll(this.series);

            this.graphseries.appear();
            chart.appear(1000, 100);
        }
    },

    watch: {
        'data': {
            deep: true,
            handler() {
                if (this.graphseries.data.length == Object.entries(this.data).length) {
                    Object.entries(this.data).forEach((e, key) => {
                        let el = {
                            "name": e[0],
                            "percent": e[1]
                        };
                        this.graphseries.data.setIndex(key, el);
                    });
                } else {
                    this.root.dispose();
                    this.series = [];
                    this.graphseries = [];
                    this.setChart();
                }
            }
        },
    }
}

</script>