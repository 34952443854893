<template>
    <div class="contenedor-grafica">

        <nav class="tipos" v-if="data.Agrupaciones">
            <a :active="selected === null" @click="selected = null">Totales</a>
            <a :active="selected == key" @click="selected = key" v-for="(el, key) in data.Agrupaciones" :key="key">{{
                            el.Agrupacion }}</a>
        </nav>


        <div id="chartdiv"></div>
    </div>
</template>

<script>

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
    name: 'grafica-barras-series',
    mounted() {
        this.setChart();
    },
    props: ['data'],
    data: () => ({
        root: '',
        series: '',
        selected: null
    }),
    methods: {
        setChart() {

            this.root = am5.Root.new("chartdiv");

            this.root.setThemes([
                am5themes_Animated.new(this.root)
            ]);

            this.root.numberFormatter.set("numberFormat", "####");

            var chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                pinchZoomX: true,
                layout: this.root.verticalLayout
            }));


            var data = new Object();
            var seriesnames = [];


            var legend = chart.children.push(
                am5.Legend.new(this.root, {
                    centerX: am5.p50,
                    x: am5.p50,
                    fixedWidthGrid: true,
                    useDefaultMarker: true

                })
            );


            let graphseries;

            if (this.selected == null) {
                graphseries = this.data.Series;
            } else {
                graphseries = this.data.Agrupaciones[this.selected].Series;
            }

            graphseries.forEach(e => {

                if (!data[e.name]) {
                    let el = {
                        year: e.name,
                    };
                    el[e.category] = e.value;
                    data[e.name] = el;
                } else {
                    data[e.name][e.category] = e.value;
                }

                if (!seriesnames.some(s => s == e.category)) {
                    seriesnames.push(e.category);
                }

            });

            data = Object.values(data);

            var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(this.root, {
                categoryField: "year",
                renderer: am5xy.AxisRendererY.new(this.root, {
                    inversed: true,
                    cellStartLocation: 0.1,
                    cellEndLocation: 0.9
                }),
                tooltip: am5.Tooltip.new(this.root, {})
            }));

            yAxis.data.setAll(data);

            var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(this.root, {
                renderer: am5xy.AxisRendererX.new(this.root, {}),
                min: 0
            }));


            var that = this;

            function makeSeries(field, name) {
                var series = chart.series.push(am5xy.ColumnSeries.new(that.root, {
                    name: name,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueXField: field,
                    categoryYField: "year",
                    sequencedInterpolation: true,
                    tooltip: am5.Tooltip.new(that.root, {
                        pointerOrientation: "horizontal",
                        labelText: "[bold]{name}[/]\n{categoryY}: {valueX}"
                    })
                }));

                series.columns.template.setAll({
                    height: am5.p100,
                    tooltipText: "{name}, {categoryX}:{valueY}",
                    tooltipY: 0
                });




                series.data.setAll(data);
                series.appear();

                legend.data.push(series);

                return series;
            }



            seriesnames.forEach(e => {
                makeSeries(e, e);
            });

            chart.appear(1000, 100);



        }
    },
    watch: {
        'selected'() {
            this.root.dispose();
            this.setChart();
        }
    }
}
</script>

<style lang="scss" scoped>
.contenedor-grafica #chartdiv {
    width: 100%;
    min-height: 800px;
}
</style>