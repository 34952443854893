<template>
    <div class="contenedor-grafica">


        <nav class="tipos" v-if="data.agrupaciones">
            <a v-for="(el, key) in data.agrupaciones" :key="key">{{ el }}</a>
        </nav>

        <div id="chartdiv"></div>
    </div>
</template>

<script>

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
    name: 'grafica-barras',
    mounted() {
        this.setChart();
    },
    props: ['data'],
    data: () => ({
        root: '',
        series: ''
    }),
    methods: {
        setChart() {

            var root = am5.Root.new("chartdiv");
            var series;

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            root.numberFormatter.set("numberFormat", "####");

            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                pinchZoomX: true,
                layout: root.verticalLayout
            }));

            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineY.set("visible", false);

            var data = new Array();

            this.data.Metas.forEach(e => {

                if (e.category == 'Real') {
                    let el = {
                        year: e.name,
                        value: e.value,
                        idindicador: e.IdIndicador,
                        meta: '',
                    };

                    data.push(el);

                } else if (e.category == 'Meta') {
                    data.forEach(d => {
                        if (d.year == e.name) {
                            d.meta = e.value;
                        }
                    })
                }
            });

            var yAxis = chart.yAxes.push(
                am5xy.CategoryAxis.new(root, {
                    categoryField: "year",
                    renderer: am5xy.AxisRendererY.new(root, {
                        cellStartLocation: 0.1,
                        cellEndLocation: 0.9
                    }),
                    tooltip: am5.Tooltip.new(root, {})
                })
            );

            yAxis.data.setAll(data);

            var xAxis = chart.xAxes.push(
                am5xy.ValueAxis.new(root, {
                    min: 0,
                    renderer: am5xy.AxisRendererX.new(root, {})
                })
            );

            var series1 = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: "Valor",
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: "value",
                categoryYField: "year",
                sequencedInterpolation: true,
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText: "[bold]{name}[/]\n{categoryY}: {valueX}"
                })
            }));

            series1.columns.template.setAll({
                height: am5.percent(80)
            });

            var series2 = chart.series.push(am5xy.LineSeries.new(root, {
                name: "Meta",
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: "meta",
                categoryYField: "year",
                sequencedInterpolation: true,
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText: "[bold]{name}[/]\n{categoryY}: {valueX}"
                })
            }));

            series2.strokes.template.setAll({
                strokeWidth: 2,
            });

            series2.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 0.5,
                    sprite: am5.Circle.new(root, {
                        radius: 5,
                        stroke: series2.get("stroke"),
                        strokeWidth: 2,
                        fill: root.interfaceColors.get("background")
                    })
                });
            });

            series1.data.setAll(data);
            series2.data.setAll(data);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series1.appear();
            series2.appear();
            chart.appear(1000, 100);



            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear(1000);
            chart.appear(1000, 100);


        }
    },
    watch: {
    }
}
</script>

<style lang="scss" scoped>
.contenedor-grafica #chartdiv {
    width: 100%;
}
</style>