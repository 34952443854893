<template>
    <div class="contenedor-grafica">
        <div id="chartdiv"></div>
    </div>
</template>

<script>

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
    name: 'grafica-barras',
    mounted() {
        this.setChart();
    },
    props: ['data'],
    data: () => ({
        root: '',
        series: ''
    }),
    methods: {
        setChart() {

            var root = am5.Root.new("chartdiv");

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            root.numberFormatter.set("numberFormat", "####");

            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                pinchZoomX: true
            }));






            // Add cursor
            // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                behavior: "none"
            }));
            cursor.lineY.set("visible", false);


            var colorSet = am5.ColorSet.new(root, {});


            // Create axes
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
            var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
                maxDeviation: 0.2,
                baseInterval: {
                    timeUnit: "year",
                    count: 1
                },
                renderer: am5xy.AxisRendererX.new(root, {}),
                tooltip: am5.Tooltip.new(root, {})
            }));

            var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {})
            }));


            // Add series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
            var series = chart.series.push(am5xy.LineSeries.new(root, {
                name: "Series",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                valueXField: "date",
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{valueY}"
                })
            }));

            series.fills.template.setAll({
                visible: true,
                fillOpacity: 0.5,
                templateField: "fillSettings"
            });


            // Add scrollbar
            // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
            chart.set("scrollbarX", am5.Scrollbar.new(root, {
                orientation: "horizontal"
            }));


            var data = new Array();

            this.data.Metas.forEach(e => {


                if (e.category == 'Real') {

                    var date = new Date(e.name - 1, 1, 1);
                    date.setHours(0, 0, 0, 0);
                    am5.time.add(date, "year", 1);

                    let el = {
                        date: date.getTime(),
                        value: e.value,
                        year: e.name,
                        meta: '',
                        fillSettings: {
                            fill: colorSet.getIndex(0),
                        },
                        bulletSettings: {
                            fill: colorSet.getIndex(0)
                        }
                    };

                    data.push(el);

                } else if (e.category == 'Meta') {
                    data.forEach(d => {
                        if (d.year == e.name) {
                            d.meta = e.value;
                        }
                    })
                }
            });


            series.data.setAll(data);

            var series2 = chart.series.push(
                am5xy.LineSeries.new(root, {
                    name: "Meta",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "meta",
                    valueXField: "date",
                    tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: "horizontal",
                        labelText: "{name} en {categoryX}: {valueY} {info}"
                    })
                })
            );

            series2.strokes.template.setAll({
                strokeWidth: 3,
                templateField: "strokeSettings"
            });


            series2.data.setAll(data);

            series2.bullets.push(function () {
                return am5.Bullet.new(root, {
                    sprite: am5.Circle.new(root, {
                        strokeWidth: 3,
                        stroke: series2.get("stroke"),
                        radius: 5,
                        fill: root.interfaceColors.get("background")
                    })
                });
            });


            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear(1000);
            chart.appear(1000, 100);


        }
    },
    watch: {
    }
}
</script>

<style lang="scss" scoped>
.contenedor-grafica #chartdiv {
    width: 100%;
}
</style>